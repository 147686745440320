import React, { useContext, useRef } from "react";
import useSWR, { mutate } from "swr";
import moment from "moment";

import { AuthContext } from "../context/authContext.js";
import http, {
  paymentUrlEndpoint,
  defaultHandler,
} from "../components/http.jsx";
import toast from "react-hot-toast";
import { PrintComponent } from "../components/printComponent.jsx";
import { useReactToPrint } from "react-to-print";
import Details from "../components/details.jsx";
import { Link } from "react-router-dom";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(paymentUrlEndpoint + id);
    mutate();
    toast.success("Payment Deleted Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 0,
    });
  }
};

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function PaymentList({ id }) {
  console.log(id);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let num = 1;
  const { currentUser } = useContext(AuthContext);

  let endPoint = paymentUrlEndpoint + currentUser.firm;
  const { isLoading, data: payment, error } = useSWR(endPoint, fetcher);
  if (isLoading) return <p>{"Payment is Loading"}</p>;

  const currentDate = new Date().toLocaleDateString();

  //console.log(payment)
  /* const changeHandler = (e) => {
    payment.filter((f) => f.rent.toLowerCase().includes(e.target.value));
  };
*/

  const filter = (id) => {
    return payment.filter((f) => f.propertyId == id);
  };
  const list = filter(id);
  // console.log(expiredFilter);

  //const expiredRents = defaultHandler(list, currentDate);
  //console.log(expiredRents)

  if (error) return <p>{error.message}</p>;

  return (
    <div className="templatemo-content-container">
      <Details
        th1={"Total Payment"}
        total={list.length}
        th2={"Expired Rents"}
        //total2={expiredRents.length}
      />

      {/*<div style={{ display: "none" }}>
        <PrintComponent
          printDate={currentDate}
          printUser={currentUser}
          payment={payment}
          ref={componentRef}
        />
      </div>
      The table */}

      {/* The search 
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>*/}
      {/* Add new payment */}
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          We are Growing,{" "}
          <strong>
            <Link to={`/addRent/${id}`} class="blue-text">
              List the new payments here
            </Link>
          </strong>
        </p>
      </div>

      <div class="templatemo-content-widget no-padding">
        <div class="panel panel-default table-responsive">
          <table class="table table-striped table-bordered templatemo-user-table">
            <thead>
              <tr>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    # <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Paid On <span class="caret"></span>
                  </a>
                </td>

                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Tenant <span class="caret"></span>
                  </a>
                </td>

                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Payment <span class="caret"></span>
                  </a>
                </td>
                <td>Duration</td>
                <td>Expiring on</td>
                <td>Edit</td>

                <td>Receipt</td>
                <td>Delete</td>
              </tr>
            </thead>
            {list
              ? list.map((payment, key) => {
                  return (
                    <tbody>
                      <tr key={key}>
                        <td>{num++}</td>
                        <td>{payment.paidDate}</td>
                        <td>{payment.tenantName}</td>
                        <td>{payment.payment}</td>
                        <td>{payment.durations}</td>
                        <td>{moment(payment.endDate).fromNow()}</td>
                        <td>
                          <Link
                            to={`/addRent/${payment.propertyId}`}
                            state={payment}
                            class="templatemo-edit-btn"
                          >
                            {" "}
                            Edit
                          </Link>
                        </td>{" "}
                        <td className="templatemo-link">
                          <>
                            <div style={{ display: "none" }}>
                              <PrintComponent
                                printDate={currentDate}
                                printUser={currentUser}
                                payment={payment}
                                ref={componentRef}
                              />{" "}
                            </div>
                            <button onClick={handlePrint}>Print!</button>
                          </>
                        </td>
                        <td
                          className="templatemo-link"
                          onClick={() => handleDelete(payment)}
                        >
                          Delete
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              : isLoading && <p>Loading...</p>}
          </table>
        </div>
      </div>
      {/* Pagination */}
      {/* <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
        <img
          src="assets/images/sunset-big.jpg"
          alt="Sunset"
          class="img-responsive content-bg-img"
        />
        <i class="fa fa-heart"></i>
        <h2 class="templatemo-position-relative white-text">Sunset</h2>
        <div class="view-img-btn-wrap">
          <a href="" class="btn btn-default templatemo-view-img-btn">
            View
          </a>
        </div>
      </div> */}
    </div>
  );
}

export default PaymentList;
