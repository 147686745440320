import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import http, { marketUrlEndpoint } from "./http";
import { AuthContext } from "../context/authContext";
import useSWR from "swr";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function TenantExcel({ property }) {
  const { currentUser } = useContext(AuthContext);
  let oga = currentUser.role === 3;
  //Select
  let endPoint = marketUrlEndpoint;
  const { isLoading, data: market, error: err } = useSWR(endPoint, fetcher);

  //
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [firms, setFirms] = useState("");
  console.log(firms);
  //console.log(data);
  let num = 1;
  const fileSelectedHandler = (e) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setData(parsedData);
    };
  };

  {
  }
  const writeHandler = async (e) => {
    e.preventDefault();
    setError(false);
    data.forEach(async (propertyItem) => {
      const title = Object.values(propertyItem)[0];
      try {
        const postItems = {
          title,
          phone: "-",
          type: "-",
          rent: "-",
          origin: "-",
          description: "-",
          units: "-",
          property,
          firm: oga ? firms : currentUser.firm,
        };
        console.log(postItems);
        const res = await http.post(`tenant`, postItems);
        toast.success(res.response);
        // window.location.replace("/property");
      } catch (error) {
        setError(true);
        toast.error(error.message, {
          duration: 10,
        });
      }
    });
  };
  return (
    <div class="templatemo-content-widget templatemo-login-widget white-bg">
      <header class="text-center">
        <p>
          or Upload an excel document containing your Tenant for this Property
        </p>
      </header>
      <div class="form-group">
        <input
          class="form-control"
          type="file"
          accept=".xlsx,.xls"
          className="subscription-input-form"
          name="file"
          onChange={fileSelectedHandler}
        />
      </div>
      {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}

      <form
        id="contact"
        onSubmit={writeHandler}
        encType="multipart/form-data"
        method="POST"
        input
        value="csrf_token()"
        className="templatemo-login-form"
      >
        {oga && (
          <div className="form-group">
            <select
              onChange={(e) => setFirms(e.target.value)}
              className="form-control"
              value={firms}
            >
              {market
                ? market.map((option) => (
                    <>
                      {" "}
                      <option value="" defaultValue="selected" hidden="hidden">
                        Choose here
                      </option>
                      <option key={option.id} value={option.title}>
                        {option.title}
                      </option>
                    </>
                  ))
                : isLoading && <p>Market Names are Loading...</p>}
            </select>
          </div>
        )}
        <table class="table table-striped table-bordered templatemo-user-table">
          <thead>
            <tr>
              <td>
                <a href="" class="white-text templatemo-sort-by">
                  # <span class="caret"></span>
                </a>
              </td>
              <td>
                <a href="" class="white-text templatemo-sort-by">
                  Property <span class="caret"></span>
                </a>
              </td>
            </tr>
          </thead>
          {data ? (
            data.map((row, key) => {
              return (
                <tbody key={key}>
                  <tr key={key}>
                    <td>{num++}</td>
                    {Object.values(row).map((value, index) => {
                      return <td key={index}>{value}</td>;
                    })}
                  </tr>
                </tbody>
              );
            })
          ) : (
            <p>Loading...</p>
          )}
        </table>
        <div class="form-group">
          <button type="submit" class="templatemo-blue-button width-100">
            Post
          </button>
        </div>
      </form>
    </div>
  );
}

export default TenantExcel;
