import React, { useContext } from "react";
import Footer from "../footer";
import { useAuth } from "../../context/authContext";

export const StatementPrint = React.forwardRef((props, ref) => {
  let num = 1;
  const { currentUser } = useAuth();
  const { account, property, data } = props;
  const currentDate = new Date().toLocaleDateString();

  const filter = (property) => {
    return data?.filter((f) => f?.id.toString() === property);
  };
  const singleProperty = filter(property);
  const title = singleProperty[0]?.title;

  return (
    <div ref={ref} class="panel panel-default table-responsive">
      <div className="templatemo-content-widget white-bg col-1 text-center">
        <i className="fa fa-times"></i>
        <h2 className="templatemo-inline-block">
          Statement of Account of {title}
        </h2>
        <h3 className="text-lowercase margin-bottom-10"></h3>
        {/* <img
                src={market && imgUrl + market.photo}
                alt="Bicycle"
                className="img-circle img-thumbnail"
              /> */}
      </div>
      <table class="table table-striped table-bordered templatemo-user-table">
        <thead>
          <tr>
            <td>
              <h3>#</h3>
            </td>
            <td>
              <h3 class="white-text templatemo-sort-by">Paid On</h3>
            </td>

            <td>
              <h3 class="white-text templatemo-sort-by">Tenant</h3>
            </td>

            <td>
              <h3 class="white-text templatemo-sort-by">Payment</h3>
            </td>
            <td>Duration</td>
          </tr>
        </thead>
        {account ? (
          account.map((payment, key) => {
            return (
              <>
                <tbody>
                  <tr key={key}>
                    <td>{num++}</td>
                    <td>{payment.paidDate.slice(0, 10)}</td>
                    <td>{payment.tenantName}</td>
                    <td>{payment.payment}</td>
                    <td>{payment.durations}</td>
                  </tr>
                </tbody>
              </>
            );
          })
        ) : (
          <p>Loading...</p>
        )}
        <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
          <p>
            Generated on <strong> {currentDate} </strong> by {""}
            <strong>{currentUser.username}</strong>
          </p>
        </div>
        <Footer />
      </table>
    </div>
  );
});
