import React, { useState, useContext, useEffect } from "react";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/authContext.js";
import http, {
  propertyUrlEndpoint,
  searchUrl,
  Url,
} from "../components/http.jsx";
import toast from "react-hot-toast";
import Details from "../components/details.jsx";
import Pagination from "../components/pagination.jsx";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(propertyUrlEndpoint + id);
    toast.success("Property Deleted Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 10,
    });
  }
};

function PropertyList({ market }) {
  const { currentUser } = useContext(AuthContext);
  //const { properties, prLoading,prerror } = useSearch(currentUser.firm, query)

  let num = 1;
  const [inputs, setInputs] = useState([]);
  //

  const [properties, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = properties.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);

  {
    useEffect(() => {
      const fetchPosts = async () => {
        //  const res = await axios.get("/posts");
        const res = await http.get(
          `property/${market ? market : currentUser.firm}`
        );
        setInputs(res.data);
        setPosts(res.data);
      };
      fetchPosts();
    }, []);
  }
  //for Search
  const changeHandler = (e) => {
    setPosts(
      inputs.filter((f) => f.title.toLowerCase().includes(e.target.value))
    );
  };

  // console.log(properties)
  return (
    <div>
      <Details
        th1={"Total Properties"}
        total={properties?.length}
        th2={"Total Cities"}
        total2={1}
      />
      {/* Add new payment */}
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          We are Growing,{" "}
          <strong>
            <Link to="/addProperty" class="blue-text">
              List the new property here
            </Link>
          </strong>
        </p>
      </div>
      {/* The search */}
      <form
        class="templatemo-search-form"
        role="search"
        action={searchUrl + "property"}
      >
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Property here"
          name="title"
          id="title"
          onChange={(e) => changeHandler(e)}
        />
      </form>

      <table class="table table-striped table-bordered templatemo-user-table">
        <thead>
          <tr>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                # <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Property <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                City <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Landlord <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Phone <span class="caret"></span>
              </a>
            </td>
            <td>Edit</td>
            <td>View</td>
            <td> Tenants</td>
            <td>Delete</td>
          </tr>
        </thead>
        {records &&
          records.map((property, key) => {
            return (
              <tbody>
                <tr key={key}>
                  <td>{num++}</td>
                  <td>
                    <Link
                      to={`/property/${property.slug}`}
                      state={property}
                      className="templatemo-link"
                    >
                      {property.title}
                    </Link>
                  </td>
                  <td>{property.city}</td>
                  <td>{property.landLord}</td>
                  <td>{property.phone}</td>
                  <td>
                    <Link
                      to={`/addProperty/${property.slug}`}
                      state={property}
                      class="templatemo-edit-btn"
                    >
                      {" "}
                      Edit
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/property/${property.slug}`}
                      state={property}
                      className="templatemo-link"
                    >
                      View
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/addTenant/${property.id}`}
                      className="templatemo-link"
                    >
                      Add Tenants
                    </Link>
                  </td>
                  <td>
                    <Link
                      to=""
                      onClick={() => handleDelete(property)}
                      class="templatemo-edit-btn"
                    >
                      Delete
                    </Link>
                  </td>
                </tr>
              </tbody>
            );
          })}
      </table>
      {/* Pagination */}
      <Pagination
        order={properties.length}
        recordsPerPage={recordsPerPage}
        paginate={paginate}
      />
    </div>
  );
}

export default PropertyList;
