import { React, useState, useContext, useRef } from "react";
import Footer from "../components/footer";

import { useNavigate, useLocation, Link } from "react-router-dom";
import http from "../components/http";
import { AuthContext } from "../context/authContext";
import toast from "react-hot-toast";

function Register(props) {
  const post = useLocation().state;
  const formRef = useRef();
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = ["Email", "Verification"];

  //   const [email, emailUser] = useState(post?.email || "");
  // let [role, setRole] = useState("");

  const [error, setError] = useState(false);
  //Select box

  // const handleSelect = () => {
  //  setvalue(value);
  //};
  // if (currentUser.role == 3) {
  //  setUser = true;
  // }
  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email) === true;
  }
  const options = [
    { label: "Staff", value: "001" },
    { label: "Admin", value: "002" },
  ];
  // Registration
  const registerHandler = async (e) => {
    e.preventDefault();
    setError(false);
    const email = formRef.current.email.value;
    const role = formRef.current.role.value;
    const verify = validateEmail(email);
    if (verify) {
      try {
        await http.post(`email/inviteMember`, {
          email,
          role,
          firm: currentUser.firm,
        });
        toast.success("Post was successful");
        window.location.replace("/");
      } catch (err) {
        console.log(err);
        toast.error(err.response.data);
        setError(true);
      }
    } else {
      toast.error("Email is Incorrect");
    }
  };

  return (
    <div class="templatemo-content col-1 light-gray-bg">
      <div class="templatemo-content-widget templatemo-login-widget white-bg">
        <header class="text-center">
          <div class="square"></div>
          <h1>Rent And Flex</h1>
          <p>Excellent Property Managers</p>
        </header>
        {error && (
          <span style={{ color: "red" }}> {error} Registration failed</span>
        )}
        <form
          ref={formRef}
          method="post"
          onSubmit={registerHandler}
          class="templatemo-login-form"
        >
          <div class="form-group">
            <div>
              <span>Enter Email of New User</span>
            </div>
            <div class="input-group">
              <input
                type="text"
                name="email"
                class="form-control"
                placeholder="Enter Email"
                required="true"
              />
              <div class="input-group-addon">
                <i class="fa fa-user fa-fw"></i>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="">
              <div>
                <span>Select Role Type</span>
              </div>

              <select className="form-control" name="role">
                {options.map((option) => (
                  <>
                    <option value="" defaultValue="selected" hidden="hidden">
                      Choose here
                    </option>
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  </>
                ))}
              </select>
            </div>
          </div>
          <div class="form-group">
            <button
              onSubmit={registerHandler}
              type="submit"
              class="templatemo-blue-button width-100"
            >
              Send Invitation Email
            </button>
          </div>
        </form>
      </div>
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          The New User Must be registered user.
          <strong>
            <Link to="https://rentandflex.com/register" class="blue-text">
              Click to Sign Up
            </Link>
          </strong>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
