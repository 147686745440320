import React from "react";

import Footer from "../components/footer";
import { Link } from "react-router-dom";
import PayCard from "../components/payCard";

const options1 = [
  {
    rate: "Monthly",
    value: "3,000",
    style: "green",
    link: "property",
    number: 1,
  },
  {
    rate: "Quarter",
    value: "10,000",
    style: "blue",
    link: "payment",
    number: 4,
  },
  {
    rate: "Bi-Annually",
    value: "15,000",
    style: "green",
    link: "property",
    number: 6,
  },
  {
    rate: "Annually",
    value: "30,000",
    //  style: "blue",
    link: "payment",
    number: 12,
  },
  // { label: "Expired Rents", value: expireRents.length, style:"pink", link:'defaults' },
];
const options2 = [
  {
    rate: "Monthly",
    value: "6,000",
    style: "green",
    link: "property",
    number: 1,
  },
  {
    rate: "Quarter",
    value: "20,000",
    style: "blue",
    link: "payment",
    number: 4,
  },
  {
    rate: "Bi-Annually",
    value: "25,000",
    style: "green",
    link: "property",
    number: 6,
  },
  {
    rate: "Annually",
    value: "60,000",
    //  style: "blue",
    link: "payment",
    number: 12,
  },
  // { label: "Expired Rents", value: expireRents.length, style:"pink", link:'defaults' },
];
const options3 = [
  {
    rate: "Monthly",
    value: "12,000",
    style: "green",
    link: "property",
    number: 1,
  },
  {
    rate: "Quarter",
    value: "30,000",
    style: "blue",
    link: "payment",
    number: 4,
  },
  {
    rate: "Bi-Annually",
    value: "50,000",
    style: "green",
    link: "property",
    number: 6,
  },
  {
    rate: "Annually",
    value: "100,000",
    //  style: "blue",
    link: "payment",
    number: 12,
  },
  // { label: "Expired Rents", value: expireRents.length, style:"pink", link:'defaults' },
];
export default function SubOptions() {
  return (
    <>
      <div className="templatemo-flex-row">
        <div className="templatemo-content col-1 light-gray-bg">
          <div className="templatemo-content-container">
            <p>
              To Remain Active, {""}
              <strong>
                <Link to="/write" class="blue-text">
                  Select here and Subscribe in few steps
                </Link>
              </strong>
            </p>
            {/*Banner Column
            {market && market.map((m) => console.log(m))}*/}
            <div className="templatemo-flex-row flex-content-row">
              <PayCard
                title={"Single User"}
                style={`blue`}
                options={options1}
              />
              <PayCard
                title={"Firm: 0-30 Properties"}
                style={`yellow`}
                options={options2}
              />
              <PayCard
                title={"Firm: Over 30 Properties"}
                style={`pink`}
                options={options3}
              />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
