import moment from "moment";
import React, { useState } from "react";
import Modal from "./modal";
import SubModal from "./modal";
import { Link } from "react-router-dom";
import { useAuth } from "../context/authContext";

export default function Subscription() {
  const [modalIsOpen, setModalOpen] = useState(true);
  const { currentUser } = useAuth();
  const time = moment(currentUser.subscriptionEnd).format("dddd, MMMM Do YYYY");
  const expire = moment().isSameOrAfter(currentUser.subscriptionEnd);

  return (
    <>
      <div class="col-1">
        <div class="templatemo-content-widget orange-bg">
          <i class="fa fa-times"></i>
          <div class="media">
            <div class="media-left">
              <a href="#"></a>
            </div>
            {expire ? <SubModal isOpen={modalIsOpen} /> : null}

            <div class="media-body">
              <h2 className="media-heading text-uppercase">Subscription</h2>
              <p>Your subscription expires on {time}</p>
            </div>
          </div>
          <div>
            <Link
              to="/pay-options"
              className="btn btn-default templatemo-view-img-btn"
            >
              Renew
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
