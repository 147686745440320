import React, { useState, useContext, useRef } from "react";
import Nav from "../components/nav";
import http, {
  propertyUrlEndpoint,
  tenantUrlEndpoint,
} from "../components/http";
//import DatePicker from "react-datepicker";
import Datetime from "react-datetime";
import { StatementPrint } from "../components/print/statementPrint";
import { Link, useLocation } from "react-router-dom";
//import "react-datepicker/dist/react-datepicker.css";
import useSWR from "swr";
import { useReactToPrint } from "react-to-print";
import { AuthContext } from "../context/authContext";
import toast from "react-hot-toast";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function Statement() {
  const { currentUser } = useContext(AuthContext);
  let endPoint = propertyUrlEndpoint + currentUser.firm;
  const { isLoading, data, error } = useSWR(endPoint, fetcher);

  const componentRef = useRef();
  const titleRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [account, setData] = useState("");
  const [property, setProperty] = useState("");
  const [errors, setErrors] = useState(false);

  // Date state
  const [startDat, setStartDate] = useState(new Date());
  //const [endDate, setEndDate] = useState(post?.startDate || new Date());
  let start = startDat._d || "";

  //EndDate

  const [endDat, setEnd] = useState(new Date());
  let end = endDat._d || "";
  //submit handler
  const writeHandler = async (e) => {
    e.preventDefault();
    setErrors(false);

    try {
      const res = await http.post(`statement`, {
        start,
        end,
        property,
      });
      setData(res.data);
      toast.success("Statement Ready, Click Download");
      handlePrint();
      //console.log(postItems);

      // const res = await axios.post("/posts", {

      // window.location.replace("/property");
      // toast.success("Added Successfully");
    } catch (err) {
      console.log(err);
      setErrors(true);
    }
  };
  if (isLoading) return <p>{"List is loading"}</p>;

  if (error) return <p>{"Error in Connection"}</p>;

  return (
    <>
      <Nav />
      <div class="templatemo-content col-1 light-gray-bg">
        <div class="templatemo-content-widget templatemo-login-widget white-bg">
          <header class="text-center">
            <div class="square"></div>
            <h1>Rent and Flex</h1>
            <p>Generate Statement of Account</p>
          </header>
          {errors && (
            <span style={{ color: "red" }}> Post is unsuccessful</span>
          )}
          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
          >
            {error && <p>{error.message}</p>}
            <div class="form-group">
              <div className="">
                <div>
                  <span>Select Property Name</span>
                </div>

                <select
                  onChange={(e) => setProperty(e.target.value)}
                  class="form-control"
                >
                  {data
                    ? data.map((option, key) => (
                        <>
                          <option value="" selected="selected" hidden="hidden">
                            {"Choose here"}
                          </option>
                          <option ref={titleRef} key={key} value={option.id}>
                            {option.title}
                          </option>
                        </>
                      ))
                    : isLoading && <p>Properties are Loading...</p>}
                </select>
              </div>
            </div>

            {/* Date Time */}
            <div class="form-group">
              <div class="checkbox squaredTwo">
                <label>
                  <span>Start Date</span>
                </label>
                <Datetime
                  value={start}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="D MMMM, YYYY"
                  timeFormat={false}
                />
              </div>
            </div>

            <div class="form-group">
              <div class="checkbox squaredTwo">
                <label>
                  <span>End Date</span>
                </label>
                <Datetime
                  value={end}
                  onChange={(date) => setEnd(date)}
                  dateFormat="D MMMM, YYYY"
                  timeFormat={false}
                />
              </div>
            </div>
            {/* Date Time 
            <div class="form-group">
              <label htmlFor="start-date">
                <span></span>Start Date:
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Select Start Time"
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </div>*/}

            {/* <div class="form-group">
              <label htmlFor="start-date">
                <span></span>End Date:
              </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="Select Start Time"
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </div>*/}
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
          <span>
            Go back to <Link to="/property">Property</Link>
          </span>{" "}
        </div>
        <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
          <button onClick={handlePrint}>
            Click to View/Download -
            <strong>
              <a href="#" class="blue-text">
                Statement of Account
              </a>
            </strong>
            {account ? (
              <div style={{ display: "none" }}>
                <StatementPrint
                  property={property}
                  account={account}
                  ref={componentRef}
                  data={data}
                />
              </div>
            ) : null}
          </button>
        </div>
      </div>
    </>
  );
}

export default Statement;
